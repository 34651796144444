#Landing {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        width: 40rem;
        font-size: 14pt;
    }

    .download {
        font-size: 14pt;
        margin: 2rem;
    }


}


#Login {
    width: 30rem;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    #LoginBoxContainer {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    #LoginBox {
        //border: 1px solid black;
        //margin: 1rem;
        //padding: 1rem;
        //border-radius: 0.3rem;
        //background-color: #DDDD88;
    }

    .beta {
        margin-top: 1rem;
        color: red;
    }


    div {
        text-align: center;
    }
}

.about {
    display: inline-block;
    text-align: center;
    width: 20rem;
    max-width: 100vw;
    font-size: 10pt;
    margin: auto;
}


error-bg=#FFDEDE;

.error {
    display: block;
    background-color: error-bg;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    text-align: center;
    margin-bottom: 0.5rem;
    border-radius: 0.2rem;
    border: 1px solid darken(error-bg, 3%);
    box-shadow: var(--box-shadow) var(--color-shadow);
}

