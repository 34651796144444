/* MVP.css v1.6.2 - https://github.com/andybrewer/mvp */

:root {
    --border-radius: 5px;
    --box-shadow: 2px 2px 10px;
    --color: #118bee;
    --color-accent: #118bee15;
    --color-bg: #fff;
    --color-bg-secondary: #e9e9e9;
    --color-secondary: #920de9;
    --color-secondary-accent: #920de90b;
    --color-shadow: #f4f4f4;
    --color-text: #000;
    --color-text-secondary: #999;
    --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    --hover-brightness: 1.2;
    --justify-important: center;
    --justify-normal: left;
    --line-height: 1.5;
    --width-card: 285px;
    --width-card-medium: 460px;
    --width-card-wide: 800px;
    --width-content: 1080px;
}

/*
@media (prefers-color-scheme: dark) {
    :root {
        --color: #0097fc;
        --color-accent: #0097fc4f;
        --color-bg: #333;
        --color-bg-secondary: #555;
        --color-secondary: #e20de9;
        --color-secondary-accent: #e20de94f;
        --color-shadow: #bbbbbb20;
        --color-text: #f7f7f7;
        --color-text-secondary: #aaa;
    }
}
*/

/* Layout */
article aside {
    background: var(--color-secondary-accent);
    border-left: 4px solid var(--color-secondary);
    padding: 0.01rem 0.8rem;
}

body {
    background: var(--color-bg);
    color: var(--color-text);
    font-family: var(--font-family);
    line-height: var(--line-height);
    margin: 0;
    overflow-x: hidden;
    padding: 1rem 0;
}

footer,
header,
main {
    margin: 0 auto;
    max-width: var(--width-content);
    padding: 2rem 1rem;
}

hr {
    background-color: var(--color-bg-secondary);
    border: none;
    height: 1px;
    margin: 4rem 0;
}

section {
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--justify-important);
}

section aside {
    border: 1px solid var(--color-bg-secondary);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow) var(--color-shadow);
    margin: 1rem;
    padding: 1.25rem;
    width: var(--width-card);
}

section aside:hover {
    box-shadow: var(--box-shadow) var(--color-bg-secondary);
}

section aside img {
    max-width: 100%;
}

[hidden] {
    display: none;
}

/* Headers */
article header,
div header,
main header {
    padding-top: 0;
}

header {
    text-align: var(--justify-important);
}

header a b,
header a em,
header a i,
header a strong {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

header nav img {
    margin: 1rem 0;
}

section header {
    padding-top: 0;
    width: 100%;
}

/* Nav */
nav {
    align-items: center;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    margin-bottom: 7rem;
}

nav ul {
    list-style: none;
    padding: 0;
}

nav ul li {
    display: inline-block;
    margin: 0 0.5rem;
    position: relative;
    text-align: left;
}

/* Nav Dropdown */
nav ul li:hover ul {
    display: block;
}

nav ul li ul {
    background: var(--color-bg);
    border: 1px solid var(--color-bg-secondary);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow) var(--color-shadow);
    display: none;
    height: auto;
    left: -2px;
    padding: .5rem 1rem;
    position: absolute;
    top: 1.7rem;
    white-space: nowrap;
    width: auto;
}

nav ul li ul li,
nav ul li ul li a {
    display: block;
}

/* Typography */
code,
samp {
    background-color: var(--color-accent);
    border-radius: var(--border-radius);
    color: var(--color-text);
    display: inline-block;
    margin: 0 0.1rem;
    padding: 0 0.5rem;
}

details {
    margin: 1.3rem 0;
}

details summary {
    font-weight: bold;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: var(--line-height);
}

mark {
    padding: 0.1rem;
}

ol li,
ul li {
    padding: 0.2rem 0;
}

p {
    margin: 0.75rem 0;
    padding: 0;
}

pre {
    margin: 1rem 0;
    max-width: var(--width-card-wide);
    padding: 1rem 0;
}

pre code,
pre samp {
    display: block;
    max-width: var(--width-card-wide);
    padding: 0.5rem 2rem;
    white-space: pre-wrap;
}

small {
    color: var(--color-text-secondary);
}

sup {
    background-color: var(--color-secondary);
    border-radius: var(--border-radius);
    color: var(--color-bg);
    font-size: xx-small;
    font-weight: bold;
    margin: 0.2rem;
    padding: 0.2rem 0.3rem;
    position: relative;
    top: -2px;
}

/* Links */
a {
    color: var(--color-secondary);
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
}

a:hover {
    filter: brightness(var(--hover-brightness));
    text-decoration: underline;
}

a b,
a em,
a i,
a strong,
button {
    border-radius: var(--border-radius);
    display: inline-block;
    font-size: medium;
    font-weight: bold;
    line-height: var(--line-height);
    margin: 0.5rem 0;
    padding: 1rem 2rem;
}

button {
    font-family: var(--font-family);
}

button:hover {
    cursor: pointer;
    filter: brightness(var(--hover-brightness));
}

a b,
a strong,
button {
    background-color: var(--color);
    border: 2px solid var(--color);
    color: var(--color-bg);
}

a em,
a i {
    border: 2px solid var(--color);
    border-radius: var(--border-radius);
    color: var(--color);
    display: inline-block;
    padding: 1rem 2rem;
}

/* Images */
figure {
    margin: 0;
    padding: 0;
}

figure img {
    max-width: 100%;
}

figure figcaption {
    color: var(--color-text-secondary);
}

/* Forms */

button:disabled,
input:disabled {
    background: var(--color-bg-secondary);
    border-color: var(--color-bg-secondary);
    color: var(--color-text-secondary);
    cursor: not-allowed;
}

button[disabled]:hover {
    filter: none;
}

form {
    border: 1px solid var(--color-bg-secondary);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow) var(--color-shadow);
    display: block;
    max-width: var(--width-card-wide);
    min-width: var(--width-card);
    padding: 1.5rem;
    text-align: var(--justify-normal);
}

form header {
    margin: 1.5rem 0;
    padding: 1.5rem 0;
}

input,
label,
select,
textarea {
    display: block;
    font-size: inherit;
    max-width: var(--width-card-wide);
}

input[type="checkbox"],
input[type="radio"] {
    display: inline-block;
}

input[type="checkbox"]+label,
input[type="radio"]+label {
    display: inline-block;
    font-weight: normal;
    position: relative;
    top: 1px;
}

input,
select,
textarea {
    border: 1px solid var(--color-bg-secondary);
    border-radius: var(--border-radius);
    margin-bottom: 1rem;
    padding: 0.4rem 0.8rem;
}

input[readonly],
textarea[readonly] {
    background-color: var(--color-bg-secondary);
}

label {
    font-weight: bold;
    margin-bottom: 0.2rem;
}

/* Tables */
table {
    border: 1px solid var(--color-bg-secondary);
    border-radius: var(--border-radius);
    border-spacing: 0;
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
    padding: 0;
    white-space: nowrap;
}

table td,
table th,
table tr {
    padding: 0.4rem 0.8rem;
    text-align: var(--justify-important);
}

table thead {
    background-color: var(--color);
    border-collapse: collapse;
    border-radius: var(--border-radius);
    color: var(--color-bg);
    margin: 0;
    padding: 0;
}

table thead th:first-child {
    border-top-left-radius: var(--border-radius);
}

table thead th:last-child {
    border-top-right-radius: var(--border-radius);
}

table thead th:first-child,
table tr td:first-child {
    text-align: var(--justify-normal);
}

table tr:nth-child(even) {
    background-color: var(--color-accent);
}

/* Quotes */
blockquote {
    display: block;
    font-size: x-large;
    line-height: var(--line-height);
    margin: 1rem auto;
    max-width: var(--width-card-medium);
    padding: 1.5rem 1rem;
    text-align: var(--justify-important);
}

blockquote footer {
    color: var(--color-text-secondary);
    display: block;
    font-size: small;
    line-height: var(--line-height);
    padding: 1.5rem 0;
}
a,
a:visited,
.fakelink {
  color: #2d69cc;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:visited:hover,
.fakelink:hover {
  color: #3883ff;
}
.active > a,
.active > a:visited,
.active > .fakelink {
  color: #000;
}
nav,
header nav,
header {
  margin-bottom: 0;
  padding-bottom: 0;
}
button {
  margin: 0;
  padding: 0.5rem 1rem;
}
.legal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.legal-container .column {
  max-width: 50rem;
}
body {
  background-color: #dd8;
  padding: 0;
  margin: 0;
  font-family: "DejaVu Serif";
}
figure figcaption {
  color: #444;
}
form {
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
form input {
  display: inline-block;
}
button {
  border: 1px solid #0c68b3;
}
.Legal {
  text-align: center;
  margin-top: 10rem;
  width: 100%;
}
.Legal a {
  margin-top: 0.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
#KGSAILogo {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #000 !important;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 2rem;
}
#KGSAILogo.full {
  font-size: 5rem;
}
#KGSAILogo .sub {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 12pt;
}
.Pricing {
  display: inline-flex;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 40rem;
  max-width: 100vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
}
.Pricing h4,
.Pricing h5 {
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #008000;
}
.Pricing h5 {
  font-size: 1.2rem;
  color: #00f;
}
.Pricing .PriceBox {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #000;
  background-color: #d8af5a;
  min-width: 15rem;
  min-height: 15rem;
}
.Pricing .PriceBox h3 {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-top: 0;
  border-bottom: 1px solid #000;
  text-align: center;
  width: 100%;
}
.Pricing .PriceBox .Price {
  flex: 1;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
/*
            .price {
                text-decoration: line-through
            }
            */
}
.Pricing .PriceBox .Price .price {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  font-size: 2.3rem;
  white-space: nowrap;
}
.Pricing .PriceBox button {
  margin: 0.5rem;
}
.Pricing .PriceContainer {
  display: inline-flex;
  flex-direction: column;
  width: 15rem;
  max-width: 100vw;
  align-items: center;
}
.Pricing .PriceContainer .PlanDetails {
  background-color: #d8af5a;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.Pricing ul {
  margin: 0;
  padding: 0;
}
.Pricing ul li {
  margin: 0;
  margin-left: 1rem;
  padding: 0;
  text-align: left;
  font-size: 11pt;
}
#Account {
  display: flex;
  flex-direction: column;
  width: 100vw;
}
#Account .blurb,
#Account .beta,
#Account .admin {
  width: 40rem;
  max-width: 100vw;
  margin: auto;
}
#Account .modify-container {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#Account .modify-container button {
  margin: 1rem;
}
#Account .beta,
#Account .admin {
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: #f00;
}
.Documentation {
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  margin: auto;
}
.Documentation .two-column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.Documentation .two-column .column {
  display: inline-block;
  max-width: 20rem;
  text-align: center;
  margin: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.Documentation h2 {
  margin-left: -1rem;
}
.Documentation h4 {
  margin-top: 4rem;
}
.Documentation h2 + h4 {
  margin-top: 0rem;
}
.Documentation sup {
  background-color: transparent;
  color: #000;
  margin: 0;
  padding: 0;
  margin-right: 0.3rem;
}
.Documentation div.sup {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: small;
}
.Documentation figure.key {
  margin-top: 2rem;
}
.Documentation figure.key figcaption {
  color: #000;
}
.Documentation figure.key img {
  float: left;
  margin-right: 0.5rem;
}
.Documentation .center {
  text-align: center;
}
.Download {
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  margin: auto;
}
.Download .red {
  color: #f00;
}
.Download h3 {
  text-align: center;
}
.Download .scroll-down-for-instructions {
  font-size: 1rem;
  text-align: center;
}
.Download h2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
}
.Download .last-update {
  font-size: 1rem;
  text-align: center;
}
.Download .download-icons {
  text-align: center;
}
.Download .download-icons a {
  display: inline-flex;
  margin: 2rem;
  flex-direction: column;
  align-items: center;
}
.Download .download-icons a img {
  width: 96px;
  height: 96px;
  display: block;
  margin: 0.5rem;
}
.Download .steps {
  display: flex;
  margin: 2rem;
  flex-direction: column;
  align-items: center;
}
.Download .steps figure {
  margin: 1rem;
}
.Download .steps figure figcaption {
  text-align: center;
}
.Download .agf {
  margin-top: 3rem;
  text-align: center;
}
.Download .installation-instructions > div {
  margin-top: 1rem;
}
#Landing {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#Landing p {
  width: 40rem;
  font-size: 14pt;
}
#Landing .download {
  font-size: 14pt;
  margin: 2rem;
}
#Login {
  width: 30rem;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Login #LoginBoxContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
#Login .beta {
  margin-top: 1rem;
  color: #f00;
}
#Login div {
  text-align: center;
}
.about {
  display: inline-block;
  text-align: center;
  width: 20rem;
  max-width: 100vw;
  font-size: 10pt;
  margin: auto;
}
.error {
  display: block;
  background-color: #ffdede;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  text-align: center;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
  border: 1px solid #ffd0d0;
  box-shadow: var(--box-shadow) var(--color-shadow);
}
#Record {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#Record table.details th,
#Record table.details td {
  text-align: left;
}
#Record .VKPlayerTableContainer {
  margin: 2rem;
}
#Record .pull-right {
  float: right;
}
#GameTable {
  display: flex;
  flex-direction: column;
}
#GameTable table .date {
  max-width: 10rem;
}
#GameTable table .game-name {
  max-width: 20em;
  overflow: hidden;
}
#GameTable table .stat {
  max-width: 2rem;
}
#GameTable div.center {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#GameTable p.buttons {
  display: flex;
}
#GameTable p.buttons span {
  flex: 1;
  flex-basis: 1;
}
