@require "mvp.css";

a, a:visited, .fakelink {
    color: #2D69CC;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #3883FF;
    }
}

&.active {
    > a, > a:visited, > .fakelink {
        color: black;
    }

}

nav, header nav, header { 
    margin-bottom: 0;
    padding-bottom: 0;
}

button {
    margin: 0;
    padding: 0.5rem 1rem;
}


//@require "[A-Z]*.styl";
@require "legal.styl";
@require "global.styl";

@require "./components/[A-Z]*/*.styl";
@require "./views/[A-Z]*/*.styl";
