.Documentation {
    padding: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    width: 50rem;
    max-width: calc(100vw - 2rem);
    margin: auto;

    .two-column  {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .column {
            display: inline-block;
            max-width: 20rem;
            text-align: center;
            margin: 1rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }

    h2 {
        margin-left: -1rem;
    }

    h4 {
        margin-top: 4rem;
    }
    h2 + h4 {
        margin-top: 0rem;
    }

    sup {
        background-color: transparent;
        color: black;
        margin: 0;
        padding: 0;
        margin-right: 0.3rem;
    }

    div.sup {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: small;
    }

    figure.key {
        margin-top: 2rem;

        figcaption {
            color: black;
        }
        img { 
            float: left;
            margin-right: 0.5rem;
        }
    }

    .center {
        text-align: center;
    }
}

