.Pricing {
    display: inline-flex;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 40rem;
    max-width: 100vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;

    h4, h5 {
        padding-top: 0.0rem;
        padding-bottom: 0.0rem;
        margin-top: 0.0rem;
        margin-bottom: 1.0rem;
        font-family: 'Work Sans', sans-serif;
        font-weight: 700;
        //font-style: italic;
        font-size: 1.5rem;
        color: green;
    }
    h5 {
        font-size: 1.2rem;
        color: blue;
    }

    .PriceBox {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        //box-shadow: 0 0 3px #888;
        border: 1px solid #000;
        background-color: #D8AF5A;
        min-width: 15rem;
        min-height: 15rem;
        //border-radius: 0.5rem;

        h3 {
            font-family: 'Work Sans', sans-serif;
            font-weight: 700;
            //font-style: italic;
            font-size: 2rem;

            font-weight: bold;
            text-transform: uppercase;
            font-size: 1.5rem;
            margin-top: 0;
            border-bottom: 1px solid #000;
            text-align: center;
            width: 100%;
        }


        .Price {
            flex: 1;
            display: inline-flex;
            align-items: center;
            flex-direction: column;
            /*
            .price {
                text-decoration: line-through
            }
            */

            .price {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                padding-right: 0.2rem;
                padding-left: 0.2rem;
                font-size: 2.3rem;
                white-space: nowrap;
            }
        }

        button {
            margin: 0.5rem;
        }
    }

    .PriceContainer {
        display: inline-flex;
        flex-direction: column;
        width: 15rem;
        max-width: 100vw;
        align-items: center;

        .PlanDetails {
            background-color: #D8AF5A;
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #000;
            border-left: 1px solid #000;
            border-right: 1px solid #000;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        li {
            margin: 0;
            margin-left: 1rem;
            padding: 0;
            text-align: left;
            font-size: 11pt;
        }
    }
}
