.Legal {
    text-align: center;
    //position: fixed;
    //bottom: 0;
    //left: 0;
    margin-top: 10rem;
    width: 100%;
    a {
        margin-top: 0.2rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
