#Account {
    display: flex;
    flex-direction: column;
    width: 100vw;

    .blurb, .beta, .admin {
        width: 40rem;
        max-width: 100vw;
        margin: auto;
    }

    .modify-container {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        button {
            margin: 1rem;
        }
    }

    .beta, .admin {
        margin-top: 3rem;
        margin-bottom: 3rem;
        color: red;
    }
}
