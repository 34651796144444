.Download {
    padding: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    width: 50rem;
    max-width: calc(100vw - 2rem);
    margin: auto;

    .red {
        color: red;
    }

    h3 {
        text-align: center;
    }

    .scroll-down-for-instructions {
        font-size: 1rem;
        text-align: center;
    }

    h2 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0;
    }

    .last-update {
        font-size: 1rem;
        text-align: center;
    }


    .download-icons {
        text-align: center;
        a {
            display: inline-flex;
            margin: 2rem;
            flex-direction: column;
            align-items: center;


            img {
                width: 96px;
                height: 96px;
                display: block;
                margin: 0.5rem;
            }
        }
    }

    .steps {
        display: flex;
        margin: 2rem;
        flex-direction: column;
        align-items: center;

        figure {
            margin: 1rem;
            figcaption {
                text-align: center;
            }
        }
    }

    .agf {
        margin-top: 3rem;
        text-align: center;
    }

    .installation-instructions {
        > div {
            margin-top: 1rem;
        }
    }
}
