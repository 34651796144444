body {
    background-color: #DDDD88;
    padding: 0;
    margin: 0;
    font-family: "DejaVu Serif";
}

figure figcaption {
    color: #444;
}

form {
    border: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    input { 
        display: inline-block;
    }
}

button {
    border: 1px solid #0C68B3;
}
